import React, {useEffect, useState} from "react";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";

function StatisticsCard({ item }) {
    const [{user}, dispatch] = useUserContext()

    const [mySchool, setMySchool] = useState([])
    const [requirementData, setRequirementData] = useState([])
    const [applicationData, setApplicationData] = useState([])

    useEffect(() => {
        onValue(ref(db, `narow/mySchool/${user}/${item.learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setMySchool([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((mySchoolItem) => {
                        setMySchool((oldArray) => [...oldArray, mySchoolItem])
                    })
                }
            }
        })
        onValue(ref(db, `narow/requirements/${user}/${item.learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setRequirementData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((requiredItem) => {
                        setRequirementData((oldArray) => [...oldArray, requiredItem])
                    })
                }
            }
        })
        onValue(ref(db, `narow/submittedApplications/${user}/${item.learnerId}`), snapshot => {
            if (snapshot.exists()) {
                setApplicationData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((appItem) => {
                        setApplicationData((oldArray) => [...oldArray, appItem])
                    })
                }
            }
        })
    }, [])

    return (
        <div className="dashboard-cols w-col w-col-4 w-col-medium-6 w-col-small-6">
            <div className="dashboard-card small dark" style={{ textAlign: "left" }}>
                <h3 className="h4 light">{`${item.firstName} ${item.surname}`}</h3>
                <div className="list-div w-clearfix">
                    <div className="list-thumbnail"></div>
                    <div className="list-heading">My Schools</div>
                    <div className="list-description">
                        <strong>{mySchool.length}</strong> added
                    </div>
                </div>
                <div className="list-div w-clearfix">
                    <div className="list-thumbnail"></div>
                    <div className="list-heading">Requirement Documents</div>
                    <div className="list-description">
                        <strong>{requirementData.length}</strong> uploaded
                    </div>
                </div>
                <div className="list-div w-clearfix">
                    <div className="list-thumbnail"></div>
                    <div className="list-heading">Enrollment Applications</div>
                    <div className="list-description">
                        <strong>{applicationData.length} submitted</strong>
                    </div>
                </div>
                <div className="donut-chart-text light"></div>
                {/*<div className="progress-bar dark">*/}
                {/*    <div data-w-id="ba349bfc-e7ab-b126-437d-ddb5646e9714" className="progress-50 green"></div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default StatisticsCard