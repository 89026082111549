import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import SEO from "../seo/SEO";

function EditApplicantPage() {
    const [{user}, dispatch] = useUserContext()
    const {learnerId} = useParams()
    const history = useNavigate()

    const [firstName, setFirstName] = useState("")
    const [middleName, setMiddleName] = useState("")
    const [surname, setSurname] = useState("")
    const [gender, setGender] = useState("")
    const [birthday, setBirthday] = useState("")
    const [race, setRace] = useState("")
    const [idType, setIdType] = useState("")
    const [idNumber, setIdNumber] = useState("")
    const [currentSchool, setCurrentSchool] = useState("")
    const [currentGrade, setCurrentGrade] = useState("")
    const [staysWith, setStaysWith] = useState("")
    const [disability, setDisability] = useState("")
    const [nationality, setNationality] = useState("")
    const [schoolProvince, setSchoolProvince] = useState("")
    const [enrollmentYear, setEnrollmentYear] = useState("")
    const [schoolTown, setSchoolTown] = useState("")
    const [gradeEntry, setGradeEntry] = useState("")

    const [studentInfo, setStudentInfo] = useState({
        firstName: firstName,
        middleName: middleName,
        surname: surname,
        gender: gender,
        birthday: birthday,
        race: race,
        idType: idType,
        idNumber: idNumber,
        currentSchool: currentSchool,
        schoolPhone: '',
        currentGrade: currentGrade,
        staysWith: staysWith,
        disability: disability,
        nationality: nationality,
        schoolProvince: schoolProvince,
        enrollmentYear: enrollmentYear,
        schoolTown: schoolTown,
        gradeEntry: gradeEntry
    })

    useEffect(() => {
        onValue(ref(db, `narow/myLearners/${user}/${learnerId}`), snapshot => {
            if (snapshot.exists()) {
                const data = snapshot.val()

                setFirstName(data.firstName)
                setMiddleName(data.middleName)
                setSurname(data.surname)
                setGender(data.gender)
                setBirthday(data.birthday)
                setRace(data.race)
                setIdType(data.idType)
                setIdNumber(data.idNumber)
                setCurrentSchool(data.currentSchool)
                setCurrentGrade(data.currentGrade)
                setStaysWith(data.staysWith)
                setDisability(data.disability)
                setNationality(data.nationality)
                setSchoolProvince(data.schoolProvince)
                setEnrollmentYear(data.enrollmentYear)
                setSchoolTown(data.schoolTown)
                setGradeEntry(data.gradeEntry)
            }
        })
    }, [learnerId])

    const handleStudentChange = name => event => {
        const value = event.target.value
        setStudentInfo({ ...studentInfo, [name]: value })
    }

    const updateApplicant = (e) => {
        e.preventDefault()

        if (studentInfo.firstName !== firstName) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                firstName: studentInfo.firstName
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                firstName: studentInfo.firstName
            }).then(() => {
                alert("First name successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.middleName !== middleName) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                middleName: studentInfo.middleName
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                middleName: studentInfo.middleName
            }).then(() => {
                alert("Middle name successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.surname !== surname) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                surname: studentInfo.surname
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                surname: studentInfo.surname
            }).then(() => {
                alert("Surname successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.race !== race) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                race: studentInfo.race
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                race: studentInfo.race
            }).then(() => {
                alert("Race successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.gender !== gender) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                gender: studentInfo.gender
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                gender: studentInfo.gender
            }).then(() => {
                alert("Gender successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.birthday !== birthday) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                birthday: studentInfo.birthday
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                birthday: studentInfo.birthday
            }).then(() => {
                alert("Birthday successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.idType !== idType) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                idType: studentInfo.idType
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                idType: studentInfo.idType
            }).then(() => {
                alert("ID type successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.idNumber !== idNumber) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                idNumber: studentInfo.idNumber
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                idNumber: studentInfo.idNumber
            }).then(() => {
                alert("ID/Passport number successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.staysWith !== staysWith) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                staysWith: studentInfo.staysWith
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                staysWith: studentInfo.staysWith
            }).then(() => {
                alert("Information successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.disability !== disability) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                disability: studentInfo.disability
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                disability: studentInfo.disability
            }).then(() => {
                alert("Disability option successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.nationality !== nationality) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                nationality: studentInfo.nationality
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                nationality: studentInfo.nationality
            }).then(() => {
                alert("Nationality successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.currentSchool !== currentSchool) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                currentSchool: studentInfo.currentSchool
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                currentSchool: studentInfo.currentSchool
            }).then(() => {
                alert("Current school name successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.currentGrade !== currentGrade) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                currentGrade: studentInfo.currentGrade
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                currentGrade: studentInfo.currentGrade
            }).then(() => {
                alert("Current grade successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.schoolProvince !== schoolProvince) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                schoolProvince: studentInfo.schoolProvince
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                schoolProvince: studentInfo.schoolProvince
            }).then(() => {
                alert("Current school province successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.schoolTown !== schoolTown) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                schoolTown: studentInfo.schoolTown
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                schoolTown: studentInfo.schoolTown
            }).then(() => {
                alert("Current school town successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.enrollmentYear !== enrollmentYear) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                enrollmentYear: studentInfo.enrollmentYear
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                enrollmentYear: studentInfo.enrollmentYear
            }).then(() => {
                alert("Enrollment year successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

        if (studentInfo.gradeEntry !== gradeEntry) {
            db.ref("narow").child(`/myLearners/${user}/${learnerId}`).update({
                gradeEntry: studentInfo.gradeEntry
            })

            db.ref("narow").child(`/profile/${user}/studentInfo/${learnerId}`).update({
                gradeEntry: studentInfo.gradeEntry
            }).then(() => {
                alert("Anticipated grade entry successfully updated")
            }).catch((err) => {
                alert(err.message)
            })
        }

    }

    return (
        <main>
            <SEO title="Edit Applicant | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.narow.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left", marginTop: 14 }}>
                <div id="flash-area"></div>
                <div className="content-block registration-form -applicant">
                    <div className="bar title bar-inset clearfix -bottom-margin">
                        <button onClick={() => history(-1)} style={{ backgroundColor: "transparent" }} className="btn-back or-line">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H18V18H0z"></path>
                                    <path fill="#5A6A8F"
                                          d="M17.1 9.6H3.078l4.761 4.761c.353.353.353.925 0 1.278s-.925.353-1.278 0l-6.29-6.3C.096 9.171 0 8.941 0 8.7c0-.24.097-.471.27-.639l6.291-6.3c.172-.165.4-.258.639-.261.239 0 .468.095.639.261.351.354.351.924 0 1.278L3.079 7.8H17.1c.497 0 .9.403.9.9s-.403.9-.9.9z"></path>
                                </g>
                            </svg>
                        </button>
                        <div className="title">Edit Applicant Information</div>
                    </div>
                    <form className="new_applicant" id="new_applicant" onSubmit={updateApplicant}>
                        <div className="res-content applicant-fields">
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">First Name</label>
                                        <input className="form-control" type="text"
                                               onChange={handleStudentChange("firstName")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="applicant_last_name">Middle Name(s)</label>
                                        <input className="form-control" type="text" id="applicant_last_name"
                                               onChange={handleStudentChange("middleName")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_first_name">Surname</label>
                                        <input className="form-control" type="text" id="applicant_first_name"
                                               onChange={handleStudentChange("surname")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_last_name">Race</label>
                                        <select id="" className="form-control" onChange={handleStudentChange("race")}>
                                            <option value="">Select Race</option>
                                            <option value="African/Black">African/Black</option>
                                            <option value="Coloured">Coloured</option>
                                            <option value="Indian">Indian</option>
                                            <option value="White">White</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_gender">Gender</label>
                                        <div className="radio-group">
                                            <div className="radio-inline custom">
                                                <label>
                                                    <input type="radio" value="Male" onChange={handleStudentChange("gender")}
                                                           id="applicant_gender_male" />
                                                    <span>Male</span>
                                                </label>
                                            </div>
                                            <div className="radio-inline custom">
                                                <label>
                                                    <input type="radio" value="Female" onChange={handleStudentChange("gender")}
                                                           id="applicant_gender_female" />
                                                    <span>Female</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group" id="datepicker-container">
                                        <label className="required" htmlFor="applicant_date_of_birth">Date of Birth</label>
                                        <input className="form-control" type="date" id="applicant_date_of_birth"
                                               onChange={handleStudentChange('birthday')} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="applicant_email" className="required">ID Type</label>
                                        <select id="" className="form-control" onChange={handleStudentChange("idType")}>
                                            <option value="">Select ID Type</option>
                                            <option value="SA Birth Certificate">SA Birth Certificate</option>
                                            <option value="SA Identiy Document">SA Identity Document</option>
                                            <option value="Foreign Passport">Foreign Passport</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">ID/Passport Number</label>
                                        <input className="form-control" type="text" id="applicant_city"
                                               onChange={handleStudentChange("idNumber")}/>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper country-select">
                                            <label className="required">Learner Stays With?</label>
                                            <select id="" className="form-control"
                                                    onChange={handleStudentChange("staysWith")}>
                                                <option value="">Select who you stay with</option>
                                                <option value="Mother">Mother</option>
                                                <option value="Father">Father</option>
                                                <option value="Parents">Parents</option>
                                                <option value="Guardian">Guardian</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper nationality-select">
                                            <label className="required">Does learner have disability?</label>
                                            <select id="" className="form-control"
                                                    onChange={handleStudentChange("disability")}>
                                                <option value="">Select disability option</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper nationality2-select">
                                            <label className="required">Nationality</label>
                                            <input type="text" className="form-control"
                                                   onChange={handleStudentChange("nationality")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Name of Current School</label>
                                        <input className="form-control" type="text" id="applicant_present_school_name"
                                               onChange={handleStudentChange("currentSchool")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper present-grade-select">
                                            <label className="required" htmlFor="applicant_present_grade">Current Grade</label>
                                            <select className="form-control" id="applicant_present_grade"
                                                    onChange={handleStudentChange("currentGrade")}>
                                                <option value="">Select Current Grade</option>
                                                <option value="RRR">Grade RRR</option>
                                                <option value="RR">Grade RR</option>
                                                <option value="R">Grade R</option>
                                                <option value="1">Grade 1</option>
                                                <option value="2">Grade 2</option>
                                                <option value="3">Grade 3</option>
                                                <option value="4">Grade 4</option>
                                                <option value="5">Grade 5</option>
                                                <option value="6">Grade 6</option>
                                                <option value="7">Grade 7</option>
                                                <option value="8">Grade 8</option>
                                                <option value="9">Grade 9</option>
                                                <option value="10">Grade 10</option>
                                                <option value="11">Grade 11</option>
                                                <option value="12">Grade 12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper school-country-select">
                                            <label className="required">Current School's Province</label>
                                            <select id="" className="form-control"
                                                    onChange={handleStudentChange("schoolProvince")}>
                                                <option value="">Select School's Province</option>
                                                <option value="Eastern Cape">Eastern Cape</option>
                                                <option value="Free State">Free State</option>
                                                <option value="Gauteng">Gauteng</option>
                                                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                                <option value="Limpopo">Limpopo</option>
                                                <option value="Mpumalanga">Mpumalanga</option>
                                                <option value="North West">North West</option>
                                                <option value="Northern Cape">Northern Cape</option>
                                                <option value="Western Cape">Western Cape</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Current School's City or Town</label>
                                        <input className="form-control" type="text" id="applicant_school_city"
                                               onChange={handleStudentChange("schoolTown")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper enrollment-year-select">
                                            <label className="required" htmlFor="applicant_enrollment_year">Enrollment Year</label>
                                            <select className="form-control" id="applicant_enrollment_year"
                                                    onChange={handleStudentChange("enrollmentYear")}>
                                                <option value="">Select Enrollment Year</option>
                                                <option value="2023">2023 Academic Year</option>
                                                <option value="2024">2024 Academic Year</option>
                                                <option value="2025">2025 Academic Year</option>
                                                <option value="2026">2026 Academic Year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper grade-select">
                                            <label className="required" htmlFor="applicant_anticipated_grade">Anticipated Grade Entry</label>
                                            <select className="form-control" id="applicant_anticipated_grade"
                                                    onChange={handleStudentChange("gradeEntry")}>
                                                <option value="">Select Anticipated Grade Entry</option>
                                                <option value="RRR">Grade RRR</option>
                                                <option value="RR">Grade RR</option>
                                                <option value="R">Grade R</option>
                                                <option value="1">Grade 1</option>
                                                <option value="2">Grade 2</option>
                                                <option value="3">Grade 3</option>
                                                <option value="4">Grade 4</option>
                                                <option value="5">Grade 5</option>
                                                <option value="6">Grade 6</option>
                                                <option value="7">Grade 7</option>
                                                <option value="8">Grade 8</option>
                                                <option value="9">Grade 9</option>
                                                <option value="10">Grade 10</option>
                                                <option value="11">Grade 11</option>
                                                <option value="12">Grade 12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bar bar-inset clearfix">
                            <div className="pull-right">
                                <input type="submit" value="Create Applicant" className="btn btn-primary"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default EditApplicantPage