import React from "react";

function Footer() {
    return (
        <div className="footer w-clearfix" style={{ minHeight: 0}}>
            <div className="right">
                Powered by <a href="http://www.only-dev.co.za" className="link">OnlyDev (Pty) Ltd</a>
            </div>
            <div className="left">
                © {new Date().getFullYear()} Narrow (Pty) Ltd
            </div>
        </div>
    )
}

export default Footer