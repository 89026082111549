export const SET_USER = 'SET_USER'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const FILTER_SCHOOLS = 'FILTER_SCHOOLS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const LOAD_SCHOOLS = 'LOAD_SCHOOLS'
export const ADD_REQUIREMENT = 'ADD_REQUIREMENT'
export const REMOVE_REQUIREMENT = 'REMOVE_REQUIREMENT'
export const CLEAR_REQUIREMENTS = 'CLEAR_REQUIREMENTS'
export const LEARNER_NAME = 'LEARNER_NAME'
export const LEARNER_SURNAME = 'LEARNER_SURNAME'
export const GET_SCHOOLS_SUCCESS = 'GET_SCHOOLS_SUCCESS'
export const UPDATE_SORT = 'UPDATE_SORT'
export const SORT_SCHOOLS = 'SORT_SCHOOLS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const REQUIREMENTS_STATUS = 'REQUIREMENTS_STATUS'
export const LEARNER_ID = 'LEARNER_ID'
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE'