import React, {useEffect, useState} from "react";
import {school} from "../data/schoolsData";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import {REQUIREMENTS_STATUS} from "../utils/actions";

function SubmitApplication({ schoolName }) {
    const [{user}, dispatch] = useUserContext()

    const [requirementStatus, setRequirementStatus] = useState(false)
    const [requirementData, setRequirementData] = useState([])

    useEffect(() => {
        onValue(ref(db, `narow/requirements/${user}`), snapshot => {
            if (snapshot.exists()) {
                setRequirementData([])

                const data = snapshot.val()

                if (data !== null) {
                    setRequirementStatus(true)
                    Object.values(data).map((item) => {
                        setRequirementData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])

    useEffect(() => {
        isAllRequirementsUploaded()
    }, [schoolName])


    const isAllRequirementsUploaded = () => {
        const selectSchoolItem = school.filter((search) => search.name === schoolName)
        // A comparer used to determine if two entries are equal.
        const isSameUser = (a, b) => a.category === b.category;

        // Get items that only occur in the left array,;
        // using the compareFunction to determine equality.
        const onlyInLeft = (left, right, compareFunction) =>
            left.filter(leftValue =>
                right.some(rightValue => compareFunction(leftValue, rightValue)));

        const onlyInA = onlyInLeft(requirementData, selectSchoolItem[0]['requirements'], isSameUser);

        const result = [...onlyInA];

        if (result.length === selectSchoolItem[0]['requirements'].length) {
            setRequirementStatus(true)
            dispatch({
                type: REQUIREMENTS_STATUS,
                requirementData: "Yes"
            })
            localStorage.setItem('requirementStatus', JSON.stringify("Yes"))
        } else {
            setRequirementStatus(false)
            dispatch({
                type: REQUIREMENTS_STATUS,
                requirementData: "No"
            })
            localStorage.setItem('requirementStatus', JSON.stringify("No"))
        }
    }

    return (
        <div className="school-profile-header">
            <div className="bar bar-primary bar-inset mobile-layout">
                <div className="title-wrap">
                    <div className="title">
                        {schoolName}
                    </div>
                </div>
                <div className="btns login-tooltip" data-toogle="popover">
                    <a className="btn btn-secondary inquiry-btn" href={`/send-enquiry/${schoolName}`}>
                        Send Enquiry
                    </a>
                    <button className={requirementStatus ? "btn btn-primary -left-margin" : "btn btn-primary -left-margin disabled"}>
                        Submit Application
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubmitApplication