import React, {useEffect, useState} from "react";
import imgPerson from "../img/ic-person.png"
import {useNavigate, useParams} from "react-router-dom";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import SEO from "../seo/SEO";

function EditProfilePage() {
    const {userId} = useParams()
    const history = useNavigate()

    const [firstName, setFirstName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [address, setAddress] = useState("")
    const [relationship, setRelationship] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [town, setTown] = useState("")
    const [province, setProvince] = useState("")
    const [postId, setPostId] = useState("")

    const [profile, setProfile] = useState({
        firstName: firstName,
        surname: surname,
        phoneNumber: phoneNumber,
        address: address,
        town: town,
        province: province,
        relationship: relationship
    })

    useEffect(() => {
        onValue(ref(db, `narow/accounts/${userId}`), snapshot => {
            if (snapshot.exists()) {
                const firstName = snapshot.child("firstName").val()
                const surname = snapshot.child("surname").val()
                const email = snapshot.child("email").val()
                const address = snapshot.child("address").val()
                const phoneNumber = snapshot.child("phoneNumber").val()
                const town = snapshot.child("town").val()
                const province = snapshot.child("province").val()
                const relationship = snapshot.child("relationship").val()

                setFirstName(firstName)
                setSurname(surname)
                setEmail(email)
                setAddress(address)
                setPhoneNumber(phoneNumber)
                setTown(town)
                setProvince(province)
                setRelationship(relationship)
            }
        })
    }, [userId])

    const handleChange = name => event => {
        const value = event.target.value
        setProfile({ ...profile, [name]: value })
    }

    const updateProfile = (e) => {
        e.preventDefault()

        if (profile.firstName !== firstName) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                firstName: profile.firstName
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                firstName: profile.firstName
            }).then(() => {
                alert("First Name updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.surname !== surname) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                surname: profile.surname
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                surname: profile.surname
            }).then(() => {
                alert("Surname updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.relationship !== relationship) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                relationship: profile.relationship
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                relationship: profile.relationship
            }).then(() => {
                alert("Relationship updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.phoneNumber !== phoneNumber) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                phoneNumber: profile.phoneNumber
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                phoneNumber: profile.phoneNumber
            }).then(() => {
                alert("Phone number updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.address !== address) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                address: profile.address
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                address: profile.address
            }).then(() => {
                alert("Street address updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.town !== town) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                town: profile.town
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                town: profile.town
            }).then(() => {
                alert("Town updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

        if (profile.province !== province) {
            db.ref("narow").child(`/accounts/${userId}`).update({
                province: profile.province
            })

            db.ref("narow").child(`/profile/${userId}/parentsInfo/${userId}`).update({
                province: profile.province
            }).then(() => {
                alert("Province updated successfully")

            }).catch((err) => {
                alert(err.message)

            })
        }

    }

    return (
        <main>
            <SEO title="Edit Profile | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.narow.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left", marginTop: 14 }}>
                <div className="content-block registration-form">
                    <div className="bar title bar-inset clearfix -bottom-margin">
                        <button className="btn-notext btn-back or-line" style={{ backgroundColor: "transparent"}} onClick={() => history(-1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H18V18H0z"></path>
                                    <path fill="#5A6A8F"
                                          d="M17.1 9.6H3.078l4.761 4.761c.353.353.353.925 0 1.278s-.925.353-1.278 0l-6.29-6.3C.096 9.171 0 8.941 0 8.7c0-.24.097-.471.27-.639l6.291-6.3c.172-.165.4-.258.639-.261.239 0 .468.095.639.261.351.354.351.924 0 1.278L3.079 7.8H17.1c.497 0 .9.403.9.9s-.403.9-.9.9z"></path>
                                </g>
                            </svg>

                        </button>
                        <div className="title">Edit Profile</div>
                    </div>
                    <form className="register" id="new_user" action="" onSubmit={updateProfile}>
                        <div className="bar bar-default parent-details -no-border-top -no-border-bottom">
                            <h5 className="form-title">Parent Details</h5>
                            <div className="row">
                                <div className="col-xs-12 col-md-6 form-group">
                                    <label className="required" htmlFor="user_parent_attributes_first_name">
                                        First Name
                                    </label>
                                    <input className="form-control" type="text" id="user_parent_attributes_first_name"
                                           onChange={handleChange("firstName")} />
                                </div>
                                <div className="col-xs-12 col-md-6 form-group">
                                    <label className="required" htmlFor="user_parent_attributes_last_name">
                                        Surname
                                    </label>
                                    <input className="form-control" type="text" id="user_parent_attributes_last_name"
                                           onChange={handleChange("surname")} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6 form-group select2-wrapper relationship">
                                    <label className="required" htmlFor="user_parent_attributes_relation_type">
                                        Relationship to Applicant
                                    </label>
                                    <select className="form-control" id="user_parent_attributes_relation_type"
                                            onChange={handleChange("relationship")}>
                                        <option value=""></option>
                                        <option value="Father">Father</option>
                                        <option value="Mother">Mother</option>
                                        <option value="Stepfather">Stepfather</option>
                                        <option value="Stepmother">Stepmother</option>
                                        <option value="Grandmother">Grandmother</option>
                                        <option value="Grandfather">Grandfather</option>
                                        <option value="Sister">Sister</option>
                                        <option value="Brother">Brother</option>
                                        <option value="Uncle">Uncle</option>
                                        <option value="Aunt">Aunt</option>
                                        <option value="Other Guardian">Other Guardian</option>
                                        <option value="Legal Guardian">Legal Guardian</option>
                                        <option value="Consultant/Recruiter">Consultant/Recruiter
                                        </option>
                                    </select>
                                </div>
                                <div className="col-xs-12 col-md-6 form-group">
                                    <label className="required">Email Address</label>
                                    <span className="form-control">{email}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Home Phone</label>
                                        <div className="js-phone-input-block phone-intl-tel-block">
                                            <div
                                                className="intl-tel-input allow-dropdown separate-dial-code iti-sdc-3">
                                                <div className="flag-container">
                                                    <div className="selected-flag">
                                                        <div className="iti-flag za"></div>
                                                        <div className="selected-dial-code">+27
                                                        </div>
                                                        {/*<div className="iti-arrow"></div>*/}
                                                    </div>
                                                </div>
                                                <input className="js-phone-input phone-input h5-tel" type="tel"
                                                       id="user_parent_attributes_applicants_attributes_0_home_phone_attributes_phone_number"
                                                       placeholder="70 123 4567" onChange={handleChange("phoneNumber")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Street Address</label>
                                        <input className="form-control" type="text" id="user_parent_attributes_applicants_attributes_0_address"
                                               onChange={handleChange("address")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required"
                                               htmlFor="user_parent_attributes_applicants_attributes_0_city">City/Town</label>
                                        <input className="form-control" type="text"
                                               id="user_parent_attributes_applicants_attributes_0_city" onChange={handleChange("town")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper country-select">
                                            <label className="required">Province</label>
                                            <select id="" className="form-control" onChange={handleChange("province")}>
                                                <option value="">Select Province</option>
                                                <option value="Eastern Cape">Eastern Cape</option>
                                                <option value="Free State">Free State</option>
                                                <option value="Gauteng">Gauteng</option>
                                                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                                <option value="Limpopo">Limpopo</option>
                                                <option value="Mpumalanga">Mpumalanga</option>
                                                <option value="North West">North West</option>
                                                <option value="Northern Cape">Northern Cape</option>
                                                <option value="Western Cape">Western Cape</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="bar bar-default applicant-details -no-border-top">*/}
                        {/*    <div className="bar title bar-inset clearfix bar-top">*/}
                        {/*        <div className="title">*/}
                        {/*            Child Details*/}
                        {/*        </div>*/}
                        {/*        <div className="right-block add-parent-wrapper">*/}
                        {/*            <a className="btn btn-default add-parent js-add-parent item add_fields" href="/applicants/new">*/}
                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">*/}
                        {/*                    <g fill="none" fillRule="evenodd">*/}
                        {/*                        <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>*/}
                        {/*                        <path fill="#859BBB"*/}
                        {/*                              d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>*/}
                        {/*                    </g>*/}
                        {/*                </svg>*/}

                        {/*                Add New Applicant*/}
                        {/*            </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <AddApplicant handleStudentChange={handleStudentChange} studentInfo={studentInfo} />*/}
                        {/*    {components.map((item, key) => (*/}
                        {/*        <div>*/}
                        {/*            <div className="bar bar-inset clearfix -no-border-bottom">*/}
                        {/*                <div className="title">*/}
                        {/*                    Child #{key + 2}*/}
                        {/*                </div>*/}
                        {/*                <button style={{ display: "flex", float: "right" }} className="btn btn-danger" onClick={() => deleteComponent(key)}>*/}
                        {/*                    <span className="btn-delete btn-delete-trash"></span>*/}
                        {/*                    <span> Remove Applicant</span>*/}
                        {/*                </button>*/}
                        {/*            </div>*/}
                        {/*            {item}*/}
                        {/*        </div>*/}
                        {/*    ))}*/}
                        {/*    <button className="btn btn-secondary add_fields" onClick={() => setComponents([...components, component])}>*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"*/}
                        {/*             viewBox="0 0 12 12">*/}
                        {/*            <g fill="none" fillRule="evenodd">*/}
                        {/*                <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>*/}
                        {/*                <path fill="#859BBB"*/}
                        {/*                      d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>*/}
                        {/*            </g>*/}
                        {/*        </svg>*/}

                        {/*        Add Applicant*/}
                        {/*    </button>*/}
                        {/*</div>*/}

                        {/*<div className="bar bar-default">*/}
                        {/*    <div className="form-title">Terms &amp; Conditions</div>*/}
                        {/*    /!*<div className="terms-wrapper">*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        By creating an account you agree to all the Terms &amp; Policies for*!/*/}
                        {/*    /!*        Parent Members available here, including but not limited to the*!/*/}
                        {/*    /!*        Terms of Service, Terms of Use and Privacy Policy.*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <h5>Submitting an inquiry and/or an application to an OpenApply*!/*/}
                        {/*    /!*        school:</h5>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        When you submit this application a Membership account will be*!/*/}
                        {/*    /!*        created for you under the Terms &amp; Policies at <a*!/*/}
                        {/*    /!*        href="http://openapply.com/terms"*!/*/}
                        {/*    /!*        target="_blank">openapply.com/terms</a>.*!/*/}
                        {/*    /!*        That Membership is linked to the agreement between the School and*!/*/}
                        {/*    /!*        OpenApply and therefore can be terminated by the School if they*!/*/}
                        {/*    /!*        terminate their agreement with OpenApply. This will not affect your*!/*/}
                        {/*    /!*        Parent Membership at <a href="http://search.openapply.com"*!/*/}
                        {/*    /!*                                target="_blank">search.openapply.com</a>.*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        By submitting this application, I am aware, understand and consent*!/*/}
                        {/*    /!*        to the following:*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        The data in this application, including any personal information,*!/*/}
                        {/*    /!*        will be irrevocably shared to the School*!/*/}
                        {/*    /!*        and will be controlled by them and subject to their policies and*!/*/}
                        {/*    /!*        processes including their Privacy Policy.*!/*/}
                        {/*    /!*        If you have questions about the School’s handling of data you should*!/*/}
                        {/*    /!*        contact them directly before submitting*!/*/}
                        {/*    /!*        this application.*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <h5>*!/*/}
                        {/*    /!*        Submitting an inquiry and/or an application to Non-OpenApply school:*!/*/}
                        {/*    /!*    </h5>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        By submitting this application, I am aware, understand and consent*!/*/}
                        {/*    /!*        to the following:*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        The data in this application, including any personal information,*!/*/}
                        {/*    /!*        will be irrevocably shared to the*!/*/}
                        {/*    /!*        School and will be controlled by them and subject to their policies*!/*/}
                        {/*    /!*        and processes including their Privacy Policy.*!/*/}
                        {/*    /!*        If you have questions about the School’s handling of data you should*!/*/}
                        {/*    /!*        contact them directly before submitting*!/*/}
                        {/*    /!*        this application.*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <cancelling>your account:</cancelling>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        By deactivating your Parent Member account and therefore cancelling*!/*/}
                        {/*    /!*        your Parent Membership, you will*!/*/}
                        {/*    /!*        no longer be able to access any of the Content and it will be*!/*/}
                        {/*    /!*        deleted per the Terms &amp; Policies available*!/*/}
                        {/*    /!*        for review here.*!/*/}
                        {/*    /!*    </p>*!/*/}
                        {/*    /!*    <p>*!/*/}
                        {/*    /!*        Deactivating your account will have no effect on any Content which*!/*/}
                        {/*    /!*        has already been shared to a School*!/*/}
                        {/*    /!*        through submitting an application or enquiry. You must contact the*!/*/}
                        {/*    /!*        school directly with any requests*!/*/}
                        {/*    /!*        regarding the Content they have already received.*!/*/}
                        {/*    /!*    </p>*!/*/}

                        {/*    /!*</div>*!/*/}
                        {/*    <a className="terms-link" target="_blank"*/}
                        {/*       href="/terms/parent-terms-of-service">+ Show Full*/}
                        {/*        Terms &amp; Conditions</a>*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-xs-12">*/}
                        {/*            <div className="checkbox">*/}
                        {/*                <label>*/}
                        {/*                    <input className="js-agree-checkbox" type="checkbox" />*/}
                        {/*                    <span>Agree</span>*/}
                        {/*                </label>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="bar bar-inset clearfix">
                            <div className="pull-right">
                                <input type="submit" name="commit" value="Update Profile" className="btn btn-primary"/>
                                {/*<span className="or">or</span>*/}
                                {/*<a className="cancel-link" href="/users/sign_up">Cancel</a>*/}
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </main>
    )
}

export default EditProfilePage