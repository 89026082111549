import React, {useState} from "react";
import imgPerson from "../img/ic-person.png"
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import {useNavigate} from "react-router-dom";
import SEO from "../seo/SEO";

function AddChildPage() {
    const [{user}, dispatch] = useUserContext()
    const history = useNavigate()
    
    const [studentInfo, setStudentInfo] = useState({
        firstName: '',
        middleName: '',
        surname: '',
        gender: '',
        birthday: '',
        race: '',
        idType: '',
        idNumber: '',
        currentSchool: '',
        schoolPhone: '',
        currentGrade: '',
        staysWith: '',
        disability: '',
        nationality: '',
        schoolProvince: '',
        enrollmentYear: '',
        schoolTown: '',
        gradeEntry: ''
    })

    const handleStudentChange = name => event => {
        const value = event.target.value
        setStudentInfo({ ...studentInfo, [name]: value })
    }

    const submitStudentInformation = (e) => {
        e.preventDefault()

        const postId = db.ref().push().key

        db.ref('narow').child(`/applicationTracking/${user}/studentInformation`).update({
            status: true
        })

        db.ref('narow').child(`/myLearners/${user}/${postId}`).set({
            firstName: studentInfo.firstName,
            middleName: studentInfo.middleName,
            surname: studentInfo.surname,
            gender: studentInfo.gender,
            birthday: studentInfo.birthday,
            race: studentInfo.race,
            idType: studentInfo.idType,
            idNumber: studentInfo.idNumber,
            currentSchool: studentInfo.currentSchool,
            schoolPhone: studentInfo.schoolPhone,
            currentGrade: studentInfo.currentGrade,
            staysWith: studentInfo.staysWith,
            disability: studentInfo.disability,
            nationality: studentInfo.nationality,
            schoolProvince: studentInfo.schoolProvince,
            enrollmentYear: studentInfo.enrollmentYear,
            schoolTown: studentInfo.schoolTown,
            gradeEntry: studentInfo.gradeEntry,
            dateAdded: new Date().getTime(),
            learnerId: postId

        })

        db.ref('narow').child(`/profile/${user}/studentInfo/${postId}`).update({
            firstName: studentInfo.firstName,
            middleName: studentInfo.middleName,
            surname: studentInfo.surname,
            gender: studentInfo.gender,
            birthday: studentInfo.birthday,
            race: studentInfo.race,
            idType: studentInfo.idType,
            idNumber: studentInfo.idNumber,
            currentSchool: studentInfo.currentSchool,
            schoolPhone: studentInfo.schoolPhone,
            currentGrade: studentInfo.currentGrade,
            staysWith: studentInfo.staysWith,
            disability: studentInfo.disability,
            nationality: studentInfo.nationality,
            schoolProvince: studentInfo.schoolProvince,
            enrollmentYear: studentInfo.enrollmentYear,
            schoolTown: studentInfo.schoolTown,
            gradeEntry: studentInfo.gradeEntry,
            dateAdded: new Date().getTime(),
            learnerId: postId

        }).then(() => {
            alert("Student information updated!")
            history('/registration')

        }, (err) => {
            alert(err.message)
        })
    }

    return (
        <main>
            <SEO title="Add New Applicant | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.narow.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left", marginTop: 14 }}>
                <div id="flash-area"></div>
                <div className="content-block registration-form -applicant">
                    <div className="bar title bar-inset clearfix -bottom-margin">
                        <button onClick={() => history(-1)} style={{ backgroundColor: "transparent" }} className="btn-back or-line">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19">
                                <g fill="none" fillRule="evenodd">
                                    <path fill="#FFF" fillOpacity="0" d="M0 0H18V18H0z"></path>
                                    <path fill="#5A6A8F"
                                          d="M17.1 9.6H3.078l4.761 4.761c.353.353.353.925 0 1.278s-.925.353-1.278 0l-6.29-6.3C.096 9.171 0 8.941 0 8.7c0-.24.097-.471.27-.639l6.291-6.3c.172-.165.4-.258.639-.261.239 0 .468.095.639.261.351.354.351.924 0 1.278L3.079 7.8H17.1c.497 0 .9.403.9.9s-.403.9-.9.9z"></path>
                                </g>
                            </svg>
                        </button>
                        <div className="title">Add New Applicant</div>
                    </div>
                    <form className="new_applicant" id="new_applicant" onSubmit={submitStudentInformation}>
                        <div className="res-content applicant-fields">
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">First Name</label>
                                        <input className="form-control" required type="text" onChange={handleStudentChange("firstName")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="applicant_last_name">Middle Name(s)</label>
                                        <input className="form-control" type="text" id="applicant_last_name" onChange={handleStudentChange("middleName")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_first_name">Surname</label>
                                        <input className="form-control" type="text" id="applicant_first_name" required
                                               onChange={handleStudentChange("surname")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_last_name">Race</label>
                                        <select id="" className="form-control" required onChange={handleStudentChange("race")}>
                                            <option value="">Select Race</option>
                                            <option value="African/Black">African/Black</option>
                                            <option value="Coloured">Coloured</option>
                                            <option value="Indian">Indian</option>
                                            <option value="White">White</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="form-group">
                                        <label className="required" htmlFor="applicant_gender">Gender</label>
                                        <div className="radio-group">
                                            <div className="radio-inline custom">
                                                <label>
                                                    <input type="radio" required value="Male" onChange={handleStudentChange("gender")}
                                                           id="applicant_gender_male" />
                                                    <span>Male</span>
                                                </label>
                                            </div>
                                            <div className="radio-inline custom">
                                                <label>
                                                    <input type="radio" required value="Female" onChange={handleStudentChange("gender")}
                                                           id="applicant_gender_female" />
                                                    <span>Female</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group" id="datepicker-container">
                                        <label className="required" htmlFor="applicant_date_of_birth">Date of Birth</label>
                                        <input className="form-control" type="date" required id="applicant_date_of_birth"
                                               onChange={handleStudentChange('birthday')} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="applicant_email" className="required">ID Type</label>
                                        <select id="" className="form-control" required onChange={handleStudentChange("idType")}>
                                            <option value="">Select ID Type</option>
                                            <option value="SA Birth Certificate">SA Birth Certificate</option>
                                            <option value="SA Identiy Document">SA Identity Document</option>
                                            <option value="Foreign Passport">Foreign Passport</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">ID/Passport Number</label>
                                        <input className="form-control" type="text" required id="applicant_city"
                                               onChange={handleStudentChange("idNumber")}/>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper country-select">
                                            <label className="required">Learner Stays With?</label>
                                            <select id="" className="form-control" required onChange={handleStudentChange("staysWith")}>
                                                <option value="">Select who you stay with</option>
                                                <option value="Mother">Mother</option>
                                                <option value="Father">Father</option>
                                                <option value="Parents">Parents</option>
                                                <option value="Guardian">Guardian</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper nationality-select">
                                            <label className="required">Does learner have disability?</label>
                                            <select id="" className="form-control" required onChange={handleStudentChange("disability")}>
                                                <option value="">Select disability option</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper nationality2-select">
                                            <label className="required">Nationality</label>
                                            <input type="text" className="form-control" required onChange={handleStudentChange("nationality")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Name of Current School</label>
                                        <input className="form-control" type="text" id="applicant_present_school_name" required
                                               onChange={handleStudentChange("currentSchool")} />
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper present-grade-select">
                                            <label className="required" htmlFor="applicant_present_grade">Current Grade</label>
                                            <select className="form-control" id="applicant_present_grade" required
                                                    onChange={handleStudentChange("currentGrade")}>
                                                <option value="">Select Current Grade</option>
                                                <option value="RRR">Grade RRR</option>
                                                <option value="RR">Grade RR</option>
                                                <option value="R">Grade R</option>
                                                <option value="1">Grade 1</option>
                                                <option value="2">Grade 2</option>
                                                <option value="3">Grade 3</option>
                                                <option value="4">Grade 4</option>
                                                <option value="5">Grade 5</option>
                                                <option value="6">Grade 6</option>
                                                <option value="7">Grade 7</option>
                                                <option value="8">Grade 8</option>
                                                <option value="9">Grade 9</option>
                                                <option value="10">Grade 10</option>
                                                <option value="11">Grade 11</option>
                                                <option value="12">Grade 12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper school-country-select">
                                            <label className="required">Current School's Province</label>
                                            <select id="" className="form-control" required onChange={handleStudentChange("schoolProvince")}>
                                                <option value="">Select School's Province</option>
                                                <option value="Eastern Cape">Eastern Cape</option>
                                                <option value="Free State">Free State</option>
                                                <option value="Gauteng">Gauteng</option>
                                                <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                                <option value="Limpopo">Limpopo</option>
                                                <option value="Mpumalanga">Mpumalanga</option>
                                                <option value="North West">North West</option>
                                                <option value="Northern Cape">Northern Cape</option>
                                                <option value="Western Cape">Western Cape</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <label className="required">Current School's City or Town</label>
                                        <input className="form-control" type="text" id="applicant_school_city" required
                                               onChange={handleStudentChange("schoolTown")} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper enrollment-year-select">
                                            <label className="required" htmlFor="applicant_enrollment_year">Enrollment Year</label>
                                            <select className="form-control" id="applicant_enrollment_year" required
                                                    onChange={handleStudentChange("enrollmentYear")}>
                                                <option value="">Select Enrollment Year</option>
                                                <option value="2023">2023 Academic Year</option>
                                                <option value="2024">2024 Academic Year</option>
                                                <option value="2025">2025 Academic Year</option>
                                                <option value="2026">2026 Academic Year</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-md-6">
                                    <div className="form-group">
                                        <div className="select2-wrapper grade-select">
                                            <label className="required" htmlFor="applicant_anticipated_grade">Anticipated Grade Entry</label>
                                            <select className="form-control" id="applicant_anticipated_grade" required
                                                    onChange={handleStudentChange("gradeEntry")}>
                                                <option value="">Select Anticipated Grade Entry</option>
                                                <option value="RRR">Grade RRR</option>
                                                <option value="RR">Grade RR</option>
                                                <option value="R">Grade R</option>
                                                <option value="1">Grade 1</option>
                                                <option value="2">Grade 2</option>
                                                <option value="3">Grade 3</option>
                                                <option value="4">Grade 4</option>
                                                <option value="5">Grade 5</option>
                                                <option value="6">Grade 6</option>
                                                <option value="7">Grade 7</option>
                                                <option value="8">Grade 8</option>
                                                <option value="9">Grade 9</option>
                                                <option value="10">Grade 10</option>
                                                <option value="11">Grade 11</option>
                                                <option value="12">Grade 12</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bar bar-inset clearfix">
                            <div className="pull-right">
                                <input type="submit" value="Create Applicant" className="btn btn-primary"/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default AddChildPage