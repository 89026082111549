import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import AddLearnerDialog from "../dialogs/AddLearnerDialog";
import {db} from "../firebase";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {Link} from "react-router-dom";
import ApplicantTable from "../components/ApplicantTable";
import SEO from "../seo/SEO";

function RegistrationPage() {
    const [{user}, dispatch] = useUserContext()

    const [open, setOpen] = useState(false)
    const [nextButton, setNextButton] = useState(false)
    const [learner, setLearner] = useState({
        firstName: '',
        surname: '',
        currentGrade: '',
        relationship: '',
        learnerId: '',
        dateAdded: ''
    })
    const [learnerData, setLearnerData] = useState([])
    const [studentInfo, setStudentInfo] = useState({
        firstName: '',
        middleName: '',
        surname: '',
        gender: '',
        birthday: '',
        race: '',
        idType: '',
        idNumber: '',
        currentSchool: '',
        schoolPhone: '',
        currentGrade: '',
        staysWith: '',
        disability: '',
        nationality: '',
        schoolProvince: '',
        enrollmentYear: '',
        schoolTown: '',
        gradeEntry: ''
    })

    const handleStudentChange = name => event => {
        const value = event.target.value
        setStudentInfo({ ...studentInfo, [name]: value })

    }

    useEffect(() => {
        onValue(ref(db, `narow/myLearners/${user}`), snapshot => {
            if (snapshot.exists()) {
                setNextButton(true)

                setLearnerData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setLearnerData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])

    const handleChange = name => event => {
        const value = event.target.value
        setLearner({...learner, [name]: value})
    }

    return (
        <main>
            <SEO title="Registration | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.narow.co.za" />
            <div className="content-wrapper" style={{ textAlign: "left" }}>
                <div id="flash-area"></div>
                <div className="content-block registration-form -applicant">
                    <div className="bar title bar-inset clearfix bar-top">
                        <div className="title">
                            Applicant Details
                        </div>
                        <div className="right-block add-parent-wrapper">
                            <a className="btn btn-default add-parent js-add-parent item add_fields" href="/applicants/new">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <g fill="none" fillRule="evenodd">
                                        <path fill="#FFF" fillOpacity="0" d="M0 0H12V12H0z"></path>
                                        <path fill="#859BBB"
                                              d="M6 0c3.314 0 6 2.686 6 6s-2.686 6-6 6-6-2.686-6-6C.001 2.687 2.687.001 6 0zm0 .8C3.128.8.8 3.128.8 6c0 2.872 2.328 5.2 5.2 5.2 2.872 0 5.2-2.328 5.2-5.2C11.2 3.128 8.872.8 6 .8zm0 1.6c.22 0 .4.18.4.4v2.8h2.8c.22 0 .4.18.4.4 0 .22-.18.4-.4.4H6.4v2.8c0 .22-.18.4-.4.4-.22 0-.4-.18-.4-.4V6.4H2.8c-.22 0-.4-.18-.4-.4 0-.22.18-.4.4-.4h2.8V2.8c0-.22.18-.4.4-.4z"></path>
                                    </g>
                                </svg>

                                Add New Applicant
                            </a>
                            {(() => {
                                if (learnerData.length !== 0) {
                                    return (
                                        <a href="/dashboard" className="btn btn-primary -left-margin">
                                            Proceed to Dashboard
                                        </a>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                    <div className="table-responsive inquiries-table">
                        <ApplicantTable applicants={learnerData} user={user} />
                    </div>
                </div>

            </div>
        </main>
    )
}

export default RegistrationPage