import {LEARNER_ID, LEARNER_NAME, REQUIREMENTS_STATUS, SET_USER} from "../utils/actions";

const fetchUserId = () => {
    let userId = localStorage.getItem('user')

    if (userId) {
        return JSON.parse(localStorage.getItem('user'))
    } else {
        return null
    }
}

const fetchSelectedLearner = () => {
    let fullName = localStorage.getItem("learner")

    if (fullName) {
        return JSON.parse(localStorage.getItem("learner"))
    } else {
        return ""
    }
}

const fetchRequirementsStatus = () => {
    let status = localStorage.getItem("requirementStatus")

    if (status) {
        return JSON.parse(localStorage.getItem("requirementStatus"))
    } else {
        return "No"
    }
}

const fetchLearnerId = () => {
    let learnerId = localStorage.getItem("learnerId")

    if (learnerId) {
        return JSON.parse(localStorage.getItem("learnerId"))
    } else {
        return ""
    }
}

export const initialState = {
    user: fetchUserId(),
    learner: fetchSelectedLearner(),
    requirementStatus: fetchRequirementsStatus(),
    learnerId: fetchLearnerId()
}

const reducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            }
        case LEARNER_NAME:
            return {
                ...state,
                learner: action.learner
            }
        case REQUIREMENTS_STATUS:
            return {
                ...state,
                requirementStatus: action.requirementStatus
            }
        case LEARNER_ID:
            return {
                ...state,
                learnerId: action.learnerId
            }
        default:
            return state
    }
}

export default reducer