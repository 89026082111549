import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {UserProvider} from "./context/userContext";
import reducer, {initialState} from "./reducer/userReducer";
import {FilterProvider} from "./context/filterContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <UserProvider initialState={initialState} reducer={reducer}>
          <FilterProvider>
              <App />
          </FilterProvider>
      </UserProvider>
  </React.StrictMode>
);
