import React, {useEffect, useState} from "react";
import Footer from "../components/Footer";
import {useUserContext} from "../context/userContext";
import {onValue, ref} from "firebase/database";
import {db} from "../firebase";
import StatisticsCard from "../components/StatisticsCard";
import SEO from "../seo/SEO";

function DashboardPage() {
    const [{user}, dispatch] = useUserContext()

    const [learnerData, setLearnerData] = useState([])
    const [parentsInfo, setParentsInfo] = useState([])
    const [enquiryData, setEnquiryData] = useState([])
    const [myLearners, setMyLearner] = useState([])

    useEffect(() => {
        onValue(ref(db, `narow/myLearners/${user}`), snapshot => {
            if (snapshot.exists()) {
                setLearnerData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setLearnerData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
        onValue(ref(db, `narow/profile/${user}/parentsInfo`), snapshot => {
            if (snapshot.exists()) {
                setParentsInfo([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setParentsInfo((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
        onValue(ref(db, `narow/enquiry/${user}`), snapshot => {
            if (snapshot.exists()) {
                setEnquiryData([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setEnquiryData((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
        onValue(ref(db, `narow/myLearners/${user}`), snapshot => {
            if (snapshot.exists()) {
                setMyLearner([])

                const data = snapshot.val()

                if (data !== null) {
                    Object.values(data).map((item) => {
                        setMyLearner((oldArray) => [...oldArray, item])
                    })
                }
            }
        })
    }, [user])


    return (
        <div>
            <SEO title="Dashboard | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://apply.narow.co.za" />
            <div className="full-container w-clearfix">
                <h1 className="h1">Dashboard</h1>
                {/*<form action="/search" className="search w-clearfix w-form">*/}
                {/*    <input type="search" className="search-input w-input" maxLength="256" name="query" placeholder="…"*/}
                {/*           id="search" required=""/>*/}
                {/*    <input type="submit" value="Search" className="search-button w-button"/>*/}
                {/*</form>*/}
                <a href="#" className="breadcrumb">
                    I am a breadcrumb / <span className="text-span-2">Dashboard</span>
                </a>
                <div className="dashboard-row w-row">
                    <div className="dashboard-cols w-col w-col-4 w-col-medium-6 w-col-small-small-stack">
                        <div className="dashboard-card short w-clearfix">
                            <div className="list-thumbnail yellow"></div>
                            <div className="short-card-percentage">
                                <strong>{learnerData.length}</strong>
                            </div>
                            <div className="list-heading">APPLICANTS</div>
                            <div className="list-description">Number of learners</div>
                        </div>
                    </div>
                    <div className="dashboard-cols w-col w-col-4 w-col-medium-6 w-col-small-small-stack">
                        <div className="dashboard-card short w-clearfix">
                            <div className="list-thumbnail pink"></div>
                            <div className="short-card-percentage">
                                <strong>{enquiryData.length}</strong>
                            </div>
                            <div className="list-heading">ENQUIRIES</div>
                            <div className="list-description">Enquiries sent and received</div>
                        </div>
                    </div>
                    <div className="dashboard-cols w-col w-col-4 w-col-medium-6 w-col-small-small-stack">
                        <div className="dashboard-card short w-clearfix">
                            <div className="list-thumbnail blue"></div>
                            <div className="short-card-percentage">
                                <strong>{parentsInfo.length}</strong>
                            </div>
                            <div className="list-heading">FAMILY</div>
                            <div className="list-description">Number of members</div>
                        </div>
                    </div>
                </div>
                <div className="dashboard-row w-row">
                    {/*<div className="dashboard-cols w-col w-col-2 w-col-medium-4 w-col-small-6">*/}
                    {/*    <div className="dashboard-card small">*/}
                    {/*        <h3 className="h4 centred">Inbound</h3>*/}
                    {/*        <div className="donut-chart-text">51%</div>*/}
                    {/*        <div className="progress-bar">*/}
                    {/*            <div data-w-id="b46d0503-f0f0-22af-2da1-4295a895b8c6" */}
                    {/*                 className="progress-50"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="dashboard-cols w-col w-col-2 w-col-medium-4 w-col-small-6">*/}
                    {/*    <div className="dashboard-card small">*/}
                    {/*        <h3 className="h4 centred">Outbound</h3>*/}
                    {/*        <div className="donut-chart-text">22%</div>*/}
                    {/*        <div className="progress-bar">*/}
                    {/*            <div data-w-id="f9ac99b4-fac0-0aff-e8c5-d6e0261e9b73" */}
                    {/*                 className="progress-50 red"></div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {myLearners.map((item, key) => (
                        <StatisticsCard item={item} key={key} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DashboardPage
