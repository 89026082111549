import React from "react";
import {DOTS, usePagination} from "../hooks/usePagination";

function Pagination({ onPageChange, totalCount, siblingCount = 1, currentPage, pageSize }) {
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize })

    if (currentPage === 0 || paginationRange.length < 2) {
        return null
    }

    const onNext =  () => {
        onPageChange(currentPage + 1)
    }

    const onPrevious = () => {
        onPageChange(currentPage - 1)
    }

    let lastPage = paginationRange[paginationRange.length - 1]

    return (
        <nav className="paginate">
            <ul className="pagination">
                <li className={currentPage === 1 ? "disabled" : ""} onClick={onPrevious}>
                    <a aria-label="Previous" rel="prev" href="#"><span
                        aria-hidden="true">Previous</span>
                    </a>
                </li>
                {paginationRange.map((pageNumber) => {
                    if (pageNumber === DOTS) {
                        return (
                            <li className="page gap">
                                <a>
                                    <span>
                                        …
                                    </span>
                                </a>
                            </li>
                        )
                    }

                    return (
                        <li className={pageNumber === currentPage ? "active" : ""} onClick={() => onPageChange(pageNumber)}>
                            <a href="#">{pageNumber}</a>
                        </li>
                    )
                })}
                <li className={currentPage === lastPage ? "disabled" : ""} onClick={onNext}>
                    <a aria-label="Previous" rel="next" href="#">
                        <span aria-hidden="true">Next</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination