import React from "react";
import {useFilterContext} from "../context/filterContext";

function Filter() {
    const { filters: {province, town, curriculum, schoolPhase, category}, updateFilters} = useFilterContext()

    return (
        <div className="right-sidebar column" id="filter-block">
            <div className="content-block">
                <form className="new_school_filter" id="new_school_filter">
                    <div className="bar title bar-inset clearfix">
                        <div className="title">
                            Filter
                        </div>
                        <div className="right-block show-mobile">
                            <a className="item close-filters" href="#">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     xlink="http://www.w3.org/1999/xlink" version="1.1" id="x_1_"
                                     x="0px" y="0px" width="20" height="20" viewBox="-0.5 0 20 20"
                                     enableBackground="new 0 0 18.866 18.866" space="preserve">
                                    <g id="x">
                                        <g>
                                            <path fill="#A6BEDC"
                                                  d="M16.103,2.763c-3.684-3.683-9.656-3.683-13.34,0c-3.684,3.684-3.684,9.656,0,13.34    c3.684,3.686,9.656,3.686,13.34,0C19.787,12.419,19.787,6.446,16.103,2.763z M13.602,13.601c-0.69,0.691-0.978,0.691-1.667,0    L9.433,11.1l-2.501,2.501c-0.691,0.691-0.977,0.691-1.667,0c-0.691-0.69-0.691-0.976,0-1.666l2.501-2.502l-2.501-2.5    c-0.691-0.692-0.691-0.977,0-1.668c0.69-0.69,0.977-0.69,1.667,0.001l2.501,2.5l2.502-2.5c0.689-0.691,0.977-0.691,1.666-0.001    c0.691,0.691,0.691,0.977,0,1.668l-2.501,2.5l2.501,2.502C14.292,12.624,14.292,12.911,13.602,13.601z"></path>
                                        </g>
                                    </g>
                                </svg>
                            </a>/
                        </div>
                    </div>
                    <div className="res-content filter-block">
                        <div className="row">
                            <div className="col-md-12 search-criterion" data-selects="">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        Province
                                        <a className="btn btn-warning btn-xs clear-filter hidden" href="#">Clear</a>
                                    </label>
                                    <select className="form-control" id="search_school_country" name="province" value={province}
                                            onChange={updateFilters}>
                                        <option value="all">All</option>
                                        <option value="Eastern Cape">Eastern Cape</option>
                                        <option value="Free State">Free State</option>
                                        <option value="Gauteng">Gauteng</option>
                                        <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                                        <option value="Limpopo">Limpopo</option>
                                        <option value="Mpumalanga">Mpumalanga</option>
                                        <option value="North West">North West</option>
                                        <option value="Northern Cape">Northern Cape</option>
                                        <option value="Western Cape">Western Cape</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 search-criterion">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        City or Town
                                        <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>
                                    </label>
                                    <input name="town" type="text" value={town} className="form-control" onChange={updateFilters}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 search-criterion">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        Curriculum
                                        <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>
                                    </label>
                                    <select className="form-control" id="search_school_programs" name="curriculum" value={curriculum} onChange={updateFilters}>
                                        <option value="all">All</option>
                                        <option value="caps">CAPS</option>
                                        <option value="ieb">CAPS (IEB)</option>
                                        <option value="cambridge">Cambridge</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 search-criterion">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        Boarding/Day
                                        <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>
                                    </label>
                                    <select name="isBoarding" className="form-control" id="search_school_types" onChange={updateFilters}>
                                        <option value="all">All</option>
                                        <option value="day">Day</option>
                                        <option value="boarding">Boarding</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12">*/}
                        {/*        <div className="form-group">*/}
                        {/*            <label>Tuition Fees (ZAR)</label>*/}
                        {/*            <div className="filters">*/}
                        {/*                <div data-end="100000" data-max="100000" data-min="0" data-start="0" data-text="$" id="fees-range"*/}
                        {/*                     className="ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all">*/}
                        {/*                    <div className="ui-slider-range ui-widget-header ui-corner-all" style={{left: "0%", width: "100%"}}></div>*/}
                        {/*                    <a className="ui-slider-handle ui-state-default ui-corner-all" href="#" style={{left: "0%"}}></a>*/}
                        {/*                    <a className="ui-slider-handle ui-state-default ui-corner-all" href="#" style={{left: "100%"}}></a>*/}
                        {/*                </div>*/}
                        {/*                <div className="amount">*/}
                        {/*                    <div className="form-group">*/}
                        {/*                        <div className="double-select custom-select">*/}
                        {/*                            <div className="input-group item">*/}
                        {/*                                <span className="input-group-addon">R</span>*/}
                        {/*                                <input className="form-control min-value" type="text"*/}
                        {/*                                       name="school_filter[tuition_start]" id="school_filter_tuition_start" />*/}
                        {/*                            </div>*/}
                        {/*                            <div className="line"></div>*/}
                        {/*                            <div className="input-group item">*/}
                        {/*                                <span className="input-group-addon">R</span>*/}
                        {/*                                <input className="form-control max-value" type="text"*/}
                        {/*                                       name="school_filter[tuition_end]" id="school_filter_tuition_end" />*/}
                        {/*                            </div>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="col-md-12 search-criterion">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        School Phase
                                        <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>
                                    </label>
                                    <select className="form-control" name="schoolPhase" onChange={updateFilters} value={schoolPhase}
                                            id="school_filter_class_size" >
                                        <option value="all">All</option>
                                        <option value="pre-school">Pre-School (Only)</option>
                                        <option value="primary">Primary Schools (Only)</option>
                                        <option value="secondary">High School (Only)</option>
                                        <option value="combined">Combined</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row">*/}
                        {/*    <div className="col-md-12 search-criterion">*/}
                        {/*        <div className="form-group select2-wrapper">*/}
                        {/*            <label className="with-button">*/}
                        {/*                Grade*/}
                        {/*                <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>*/}
                        {/*            </label>*/}
                        {/*            <select className="form-control" id="school_filter_grades" name="grade" onChange={updateFilters}>*/}
                        {/*                <option value="">Select Current Grade</option>*/}
                        {/*                <option value="all"></option>*/}
                        {/*                <option value="RRR">Grade RRR</option>*/}
                        {/*                <option value="RR">Grade RR</option>*/}
                        {/*                <option value="R">Grade R</option>*/}
                        {/*                <option value="1">Grade 1</option>*/}
                        {/*                <option value="2">Grade 2</option>*/}
                        {/*                <option value="3">Grade 3</option>*/}
                        {/*                <option value="4">Grade 4</option>*/}
                        {/*                <option value="5">Grade 5</option>*/}
                        {/*                <option value="6">Grade 6</option>*/}
                        {/*                <option value="7">Grade 7</option>*/}
                        {/*                <option value="8">Grade 8</option>*/}
                        {/*                <option value="9">Grade 9</option>*/}
                        {/*                <option value="10">Grade 10</option>*/}
                        {/*                <option value="11">Grade 11</option>*/}
                        {/*                <option value="12">Grade 12</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row">
                            <div className="col-md-12 search-criterion">
                                <div className="form-group select2-wrapper">
                                    <label className="with-button">
                                        Category
                                        <a className="btn btn-warning btn-xs clear-filter" href="#">Clear</a>
                                    </label>
                                    <select className="form-control" id="school_filter_school_type" name="category" value={category} onChange={updateFilters}>
                                        <option value="all">All</option>
                                        <option value="co-educational">Co-educational</option>
                                        <option value="boys">Boy's School</option>
                                        <option value="girls">Girl's School</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="bar bar-inset clearfix bar-bottom">*/}
                    {/*    <button className="btn btn-primary" type="submit">Apply Filters</button>*/}
                    {/*    <a className="btn btn-default hide-filter item" href="#">Hide Advanced Filters</a>*/}
                    {/*</div>*/}
                </form>
            </div>
        </div>
    )
}

export default Filter