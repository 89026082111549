import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@mui/material";

function UploadProgressDialog({ open, percent, setOpen }) {
    const [maxWidth] = useState("md")

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} open={open}>
            <DialogTitle>Uploading... {percent}/100</DialogTitle>
            <DialogContent>
                <LinearProgress style={{marginTop: '32px', marginBottom: '32px' }} value={percent} variant="determinate" />
            </DialogContent>
            <DialogActions>
                {(() => {
                    if (percent === '100') {
                        return (
                            <button className="btn btn-primary" onClick={() => setOpen(false)}>
                                Close
                            </button>
                        )
                    }
                })()}
            </DialogActions>
        </Dialog>
    )
}

export default UploadProgressDialog