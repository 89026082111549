export const tips = [
    {
        title: "This tab is your Dashboard. After you add a college to your list, you will see your application progress here."
    },
    {
        title: "All colleges will need you to answer the common questions under your Application Form tab."
    },
    {
        title: "To apply to a college, head over to the College Search tab."
    },
    {
        title: "Once you have added a college, you can complete and submit your application in the My College tab"
    }
]

export const dashLinks = [
    {
        key: 1,
        title: "Learner Information",
        url: '/my-application/learner-information'
    },
    {
        key: 2,
        title: "Parent Information",
        url: "/my-application/parent-information"
    },
    {
        key: 3,
        title: "Schools Search",
        url: '/my-application/schools-search'
    },
    {
        key: 4,
        title: "My Schools",
        url: '/my-application/my-schools'
    }
]

export const preSchoolGrades = [
    {
        title: "Select"
    },
    {
        title: "<6 months"
    },
    {
        title: ">6 months"
    },
    {
        title: "Grade RRR"
    },
    {
        title: "Grade RR"
    },
    {
        title: "Grade R"
    }
]

export const primaryGrades = [
    {
        title: "Select"
    },
    {
        title: "Grade 1"
    },
    {
        title: "Grade 2"
    },
    {
        title: "Grade 3"
    },
    {
        title: "Grade 4"
    },
    {
        title: "Grade 5"
    },
    {
        title: "Grade 6"
    },
    {
        title: "Grade 7"
    }
]

export const highSchoolGrades = [
    {
        title: "Select"
    },
    {
        title: "Grade 8"
    },
    {
        title: "Grade 9"
    },
    {
        title: "Grade 10"
    },
    {
        title: "Grade 11"
    },
    {
        title: "Grade 12"
    }
]

export const requirementList = [
    {
        name: 'Learner Birth Certificate or ID or Passport Copy'
    },
    {
        name: 'Learner Current Progress Report'
    },
    {
        name: 'Learner Immunization Card'
    },
    {
        name: 'Parent ID or Passport Copy'
    },
    {
        name: 'Parent Work Permit'
    },
    {
        name: 'Learner Study Permit'
    },
    {
        name: 'Proof of Residence'
    },
    {
        name: '3 months Bank Statement'
    }
]

export const grades = [
    {
        grade: '3 months - 2 years'
    },
    {
        grade: 'Grade RRR'
    },
    {
        grade: 'Grade RR'
    },
    {
        grade: 'Grade R'
    },
    {
        grade: 'Grade 1'
    },
    {
        grade: 'Grade 2'
    },
    {
        grade: 'Grade 3'
    },
    {
        grade: 'Grade 4'
    },
    {
        grade: 'Grade 5'
    },
    {
        grade: 'Grade 6'
    },
    {
        grade: 'Grade 7'
    },
    {
        grade: 'Grade 8'
    },
    {
        grade: 'Grade 9'
    },
    {
        grade: 'Grade 10'
    },
    {
        grade: 'Grade 11'
    },
    {
        grade: 'Grade 12'
    },
]

export const category = [
    {
        name: "Preschool",
        description: "Children aged from 3 months to 6 years",
        url: "pre-school"
    },
    {
        name: "Primary",
        description: "Schools with grade 1 to grade 7",
        url: "primary"
    },
    {
        name: "High School/College",
        description: "Schools with grade 8 to grade 12",
        url: "high-school"
    },
    {
        name: "Combined",
        description: "Schools with grade R to grade 12",
        url: "combined"
    }
]

export const contactUs = [
    {
        name: "Support",
        email: 'support@narow.co.za',
        url: "https://assets.website-files.com/63bed0273cfe5e3f80742329/63bf03ff064b62c38fe19fcd_support-aggregator-x-webflow-template.svg",
        description: "Contact us if you are experiencing technical issues on our site."
    },
    {
        name: "Careers",
        email: 'careers@narow.co.za',
        url: "https://assets.website-files.com/63bed0273cfe5e3f80742329/63bf03edba32771b033ee866_star-circle-aggregator-x-webflow-template.svg",
        description: "Looking for a job? Send us your CV and Motivation Letter."
    },
    {
        name: "Partnerships",
        email: 'onboarding@narow.co.za',
        url: "https://assets.website-files.com/63bed0273cfe5e3f80742329/63bf034da708770ad752377f_-aggregator-x-webflow-template.svg",
        description: "Contact us to learn more on how to become a partner school."
    }
]

export const mySchoolLinks = [
    {
        title: "School Information",
        url: 'about'
    },
    {
        title: "Application Form",
        url: "form"
    },
    {
        title: "Requirements",
        url: "documents"
    },
    {
        title: "Review & Submit",
        url: "review"
    }
]

export const schoolDashboardLinks = [
    {
        title: "Dashboard",
        link: "dashboard"
    },
    {
        title: "Applicants",
        link: "applicants"
    },
    {
        title: "Inquiries",
        link: "inquiries"
    },
    {
        title: "Settings",
        link: "settings"
    },
    {
        title: "Help & Support",
        link: "help-and-support"
    },
    {
        title: "Logout"
    }
]

export const courses = [
    {
        courseName: "Structural Analysis I",
        courseCode: "CIVN3026",
        period: "Semester 1",
        status: "Completed",
        enrollment: "Student",
        link: "structural-analysis-i"
    },
    {
        courseName: "Introduction to Environmental Engineering",
        courseCode: "CIVN2013",
        period: "Semester 2",
        status: "Not Started",
        enrollment: "Student",
        link: "introduction-to-environmental-engineering"
    },
    {
        courseName: "Hydraulics Engineering",
        courseCode: "CIVN3017",
        period: "Full Year",
        status: "In Progress",
        enrollment: "Student",
        link: "hydraulics-engineering"
    }
]

export const navLinks = [
    {
        title: 'Dashboard',
        url: '/dashboard'
    },
    {
        title: 'My Application',
        url: '/my-application/learner-information'
    },
    {
        title: 'My Enquiries',
        url: '/my-enquiry'
    },
    {
        title: 'Profile',
        url: '/profile'
    }
]

export const profileLinks = [
    {
        title: "Personal Information"
    },
    {
        title: "Family Information"
    }
]
