import React from "react";
import {dashLinks} from "../utils/constants";
import {NavLink} from "react-router-dom";

function TabLayout() {
    return (
        <div className="tabs-menu w-clearfix w-tab-menu">
            {dashLinks.map((item) => (
                <NavLink to={item.url} className={({isActive}) => isActive ?
                    "tab w-inline-block w-tab-link w--current" : "tab w-inline-block w-tab-link"}>
                    <div>{item.title}</div>
                </NavLink>
            ))}
        </div>
    )
}

export default TabLayout