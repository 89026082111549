import React, {useState} from "react";
import {navLinks} from "../utils/constants";
import {NavLink} from "react-router-dom";
import "../App.css"

function Header() {
    const [navOpen, setNavOpen] = useState(false)

    const handleNavOpen = () => {
        if (navOpen) {
            setNavOpen(false)
        } else {
            setNavOpen(true)
        }
    }

    return (
        <div className="main-content">
            <div id="Navbar" data-collapse="medium" className="navbar w-nav">
                <div className="container empty w-clearfix">
                    <div className='logo w-inline-block sd-logo' style={{ paddingTop: 15, paddingBottom: 0 }}>
                        <a className="applogo application-logo" href="/dashboard">
                            <div className='logo-box'>
                            </div>
                            <div className='appname'>
                                <span className='top-text' style={{ color: "white", fontFamily: "Open Sans"}}>
                                    EASY<span className='green'> Applications</span>
                                </span>
                                <span className='sub-text' style={{ color: "white", fontSize: 10.4 }}>
                                    ELECTRONIC ADMISSION SYSTEM
                                </span>
                            </div>
                        </a>
                    </div>
                    <nav role="navigation" className="nav-menu w-clearfix w-nav-menu">
                        {navLinks.map((item) => (
                            <NavLink to={item.url} className={({isActive}) =>
                                isActive ? "lhn-links w-inline-block w--current" : "lhn-links w-inline-block"}>
                                {item.title}
                            </NavLink>
                        ))}
                    </nav>
                    <div className="menu-button w-nav-button" onClick={handleNavOpen}>
                        <div className="icon-2 w-icon-nav-menu"></div>
                    </div>
                </div>
                <div style={{display: navOpen ? "block" : "none"}}>
                    <nav role="navigation" className="nav-menu w-clearfix w-nav-menu w--nav-menu-open"
                         style={{transform: `translateY(0px)`, transition: `transform 400ms ease 0s`}}>
                        {navLinks.map((item) => (
                            <NavLink to={item.url} onClick={handleNavOpen} className={({isActive}) =>
                                isActive ? "lhn-links w-inline-block w--current" : "lhn-links w-inline-block"}>
                                {item.title}
                            </NavLink>
                        ))}
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default Header