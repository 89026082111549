import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useUserContext} from "../context/userContext";
import {auth, db} from "../firebase";
import {SET_USER} from "../utils/actions";
import {onValue, ref} from "firebase/database"
import SEO from "../seo/SEO";

function LoginPage() {
    const [{user}, dispatch] = useUserContext()

    const history = useNavigate()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const login = (e) => {
        e.preventDefault()

        auth.signInWithEmailAndPassword(email, password)
            .then((authUser) => {
                dispatch({
                    type: SET_USER,
                    user: authUser.user.uid
                })
                localStorage.setItem('user', JSON.stringify(authUser.user.uid))

                onValue(ref(db, `narow/myLearners/${authUser.user.uid}`), snapshot => {
                    if (snapshot.exists()) {
                        history("/dashboard")
                    } else {
                        history("/registration")

                    }
                })
            }).catch((err) => {
            alert(err.message)
        })

    }

    return (
        <div>
            <div className='login'>
                <div id='flash-area'></div>
                <SEO title="Login | Narow"
                     description="Narow is a central application system of South African pre-schools, primary and high schools."
                     canonicalURL="https://apply.narow.co.za" />
                <form className="sign-in-form" id="new_user" style={{ textAlign: "left" }} onSubmit={login}>
                    <div className='sd-logo -form'>
                        <a className="applogo application-logo" href="/">
                            <div className='logo-box'>
                                {/*<img className="image" src="https://search.openapply.com/assets/logo-15f3c934eb8df6c885b755c59d9ad744d6e6bbd26a005f5d717536c8ff2ce263.png"/>*/}
                            </div>
                            <div className='appname'>
                                <span className='top-text'>
                                    Narow<span className='green'> Applications</span>
                                </span>
                            </div>
                        </a>
                        <h3 className='apptitle'>Sign In</h3>
                    </div>
                    <div className='fields'>
                        <div className='form-group'>
                            <label className="required" htmlFor="user_email">Email</label>
                            <input required="required" className="form-control" type="email" value={email} id="user_email"
                                   onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className='form-group'>
                            <label className="required" htmlFor="user_password">Password</label>
                            <input required="required" className="form-control" type="password" id="user_password" value={password}
                                   onChange={e => setPassword(e.target.value)}/>
                        </div>
                        {/*<div className='form-group checkbox-wrap'>*/}
                        {/*    <div className='checkbox'>*/}
                        {/*        <label className='checkbox'>*/}
                        {/*            <input name="user[remember_me]" type="hidden" value="0"/>*/}
                        {/*            <input type="checkbox" value="1" name="user[remember_me]" id="user_remember_me"/>*/}
                        {/*            <span>Remember me for 28 days (This is a trusted computer that only I have access to)</span>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div className='actions -top-margin'>
                        <input type="submit" name="commit" value="Sign In" className="btn btn-primary"
                               data-disable-with="Sign In"/>
                    </div>
                    <div className='pass-revert-box text-center'>
                        <a className="pass-revert" href="/forget-paswword">Forgot password?</a>
                    </div>
                    <div className='or-separator'>or
                    </div>
                    <div className='signup-note'>
                        <a className="btn btn-default btn-link" href="/sign-up">Create an account</a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginPage