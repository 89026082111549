import React, {useEffect} from "react";
import {useFilterContext} from "../context/filterContext";

function Sort() {
    const {sort, updateSort, pageSize, updatePageNumber, allSchools} = useFilterContext()

    return (
        <div className="bar title bar-inset clearfix">
            <div className="title">
                Search Results
            </div>
            <div className="right-block">
                <div className="select2-wrapper item">
                    <select name="sort_by" id="sort_by" className="form-control" value={sort} onChange={updateSort}>
                        <option value="name-a">School Name: A - Z</option>
                        <option value="name-z">School Name: Z - A</option>
                        {/*<option value="tuition-lowest">Tuition: Lowest - Highest</option>*/}
                        {/*<option value="tuition-highest">Tuition: Highest - Lowest</option>*/}
                        {/*<option value="phase-lowest">School Phase: Lowest - Highest</option>*/}
                        {/*<option value="phase-highest">School Phase: Highest - Lowest</option>*/}
                    </select>
                </div>
                <div className="select2-wrapper item">
                    <select name="per_page" id="per_page" className="form-control" value={pageSize} onChange={updatePageNumber}>
                        <option value="10">10 schools per page</option>
                        <option value="25">25 schools per page</option>
                        <option value="50">50 schools per page</option>
                        <option value="100">100 schools per page</option>
                    </select>
                </div>
                <a className="btn btn-primary show-filter item" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                         preserveAspectRatio="xMidYMid" width="33" height="31" viewBox="0 0 33 31">
                        <path fill="#a6bedc"
                              d="M30.500,12.501 L10.500,12.501 C9.948,12.501 9.500,12.053 9.500,11.502 C9.500,10.948 9.948,10.501 10.500,10.501 L30.500,10.501 C31.052,10.501 31.501,10.948 31.501,11.502 C31.501,12.053 31.052,12.501 30.500,12.501 ZM28.500,4.501 L10.500,4.501 C9.948,4.501 9.500,4.053 9.500,3.500 C9.500,2.948 9.948,2.501 10.500,2.501 L28.500,2.501 C29.052,2.501 29.500,2.948 29.500,3.500 C29.500,4.053 29.052,4.501 28.500,4.501 ZM3.501,29.501 C2.395,29.501 1.500,28.605 1.500,27.500 C1.500,26.395 2.395,25.502 3.501,25.502 C4.604,25.502 5.500,26.395 5.500,27.500 C5.500,28.605 4.604,29.501 3.501,29.501 ZM3.501,21.500 C2.395,21.500 1.500,20.605 1.500,19.501 C1.500,18.396 2.395,17.501 3.501,17.501 C4.604,17.501 5.500,18.396 5.500,19.501 C5.500,20.605 4.604,21.500 3.501,21.500 ZM3.501,13.500 C2.395,13.500 1.500,12.605 1.500,11.502 C1.500,10.396 2.395,9.501 3.501,9.501 C4.604,9.501 5.500,10.396 5.500,11.502 C5.500,12.605 4.604,13.500 3.501,13.500 ZM3.501,5.501 C2.395,5.501 1.500,4.605 1.500,3.500 C1.500,2.396 2.395,1.501 3.501,1.501 C4.604,1.501 5.500,2.396 5.500,3.500 C5.500,4.605 4.604,5.501 3.501,5.501 ZM10.500,18.501 L22.500,18.501 C23.053,18.501 23.500,18.949 23.500,19.500 C23.500,20.052 23.053,20.501 22.500,20.501 L10.500,20.501 C9.948,20.501 9.500,20.052 9.500,19.500 C9.500,18.949 9.948,18.501 10.500,18.501 ZM10.500,26.500 L26.500,26.500 C27.052,26.500 27.499,26.948 27.499,27.500 C27.499,28.052 27.052,28.500 26.500,28.500 L10.500,28.500 C9.948,28.500 9.500,28.052 9.500,27.500 C9.500,26.948 9.948,26.500 10.500,26.500 Z"></path>
                    </svg>
                    Show filter
                </a>
            </div>
        </div>
    )
}

export default Sort