import React, {createContext, useContext, useEffect, useReducer} from "react";
import filterReducer from "../reducer/filterReducer";
import {
    FILTER_SCHOOLS,
    LOAD_SCHOOLS,
    SORT_SCHOOLS,
    UPDATE_FILTERS,
    UPDATE_PAGE_SIZE,
    UPDATE_SORT
} from "../utils/actions";

import schoolData from "../data/private_college.json"

const filterInitialState = {
    filteredSchools: [],
    allSchools: [],
    sort: "name-a",
    pageSize: 25,
    filters: {
        province: 'all',
        town: '',
        curriculum: 'all',
        isBoarding: 'all',
        schoolPhase: 'all',
        grade: 'all',
        category: 'all'
    }
}

const FilterContext = createContext()

export const FilterProvider = ({ children }) => {
    const [state, dispatch] = useReducer(filterReducer, filterInitialState)

    useEffect(() => {
        dispatch({
            type: LOAD_SCHOOLS,
            payload: schoolData.school.filter((search) => search.type === "School (Private)")
        })
    }, [schoolData])

    useEffect(() => {
        dispatch({ type: SORT_SCHOOLS })
        // dispatch({ type: FILTER_SCHOOLS })
    }, [state.sort])

    const updateSort = (e) => {
        const value = e.target.value
        dispatch({
            type: UPDATE_SORT,
            payload: value
        })
    }

    const updatePageNumber = (e) => {
        const value = e.target.value
        dispatch({
            type: UPDATE_PAGE_SIZE,
            payload: value
        })
    }

    const updateFilters = (e) => {
        let name = e.target.name
        let value = e.target.value

        dispatch({
            type: UPDATE_FILTERS,
            payload: {name, value}
        })
    }

    return (
        <FilterContext.Provider value={{ ...state, updateSort, updateFilters, updatePageNumber }}>
            {children}
        </FilterContext.Provider>
    )
}

export const useFilterContext = () => {
    return useContext(FilterContext)
}