import React from "react";
import {
    FILTER_SCHOOLS,
    LOAD_SCHOOLS,
    SORT_SCHOOLS,
    UPDATE_FILTERS,
    UPDATE_PAGE_SIZE,
    UPDATE_SORT
} from "../utils/actions";

const filterReducer = (state, action) => {
    if (action.type === LOAD_SCHOOLS) {
        return {
            ...state,
            allSchools: [...action.payload],
            filteredSchools: [...action.payload]
        }
    }

    if (action.type === UPDATE_SORT) {
        return {
            ...state,
            sort: action.payload
        }
    }

    if (action.type === UPDATE_PAGE_SIZE) {
        return {
            ...state,
            pageSize: action.payload
        }
    }

    if (action.type === SORT_SCHOOLS) {
        const {sort, allSchools} = state
        let tempSchools = []

        if (sort === "name-a") {
            tempSchools = allSchools.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
        }

        if (sort === "name-z") {
            tempSchools = allSchools.sort((a, b) => {
                return b.name.localeCompare(a.name)
            })
        }

        if (sort === 'tuition-lowest') {
            tempSchools = allSchools.sort((a, b) => {
                return a.schoolFees[0].fee - b.schoolFees[0].fee
            })
        }

        if (sort === 'tuition-highest') {
            tempSchools = allSchools.sort((a, b) => {
                return b.schoolFees[b.schoolFees.length - 1].fee - a.schoolFees[a.schoolFees.length - 1].fee
            })
        }

        if (sort === 'phase-lowest') {
            tempSchools = allSchools.sort((a, b) => {
                return a.lowestGrade - b.lowestGrade
            })
        }

        if (sort === 'phase-highest') {
            tempSchools = allSchools.sort((a, b) => {
                return b.lowestGrade - a.lowestGrade
            })
        }

        return { ...state, allSchools: tempSchools}
    }

    if (action.type === UPDATE_FILTERS) {
        const { name, value } = action.payload
        return {
            ...state,
            filters: {...state.filters, [name]: value}
        }
    }

    // if (action.type === FILTER_SCHOOLS) {
    //     const {allSchools} = state
    //     const {province, town, curriculum, isBoarding, schoolPhase, grade, category} = state.filters
    //
    //     let tempSchools = [...allSchools]
    //
    //     if (province !== 'all') {
    //         tempSchools = tempSchools.filter((search) => search.province !== province)
    //         console.log(tempSchools)
    //
    //     } else {
    //         tempSchools = allSchools
    //     }
    //
    //     if (town) {
    //         tempSchools = tempSchools.filter((search) => search.physical_address.includes(town))
    //     }
    //
    //     if (curriculum !== 'all') {
    //         tempSchools = tempSchools.filter((search) => search.curriculum === curriculum)
    //     }
    //
    //     if (schoolPhase !== 'all') {
    //         tempSchools = tempSchools.filter((search) => search.schoolPhase === schoolPhase)
    //     }
    //
    //     if (category !== 'all') {
    //         tempSchools = tempSchools.filter((search) => search.category === category)
    //     }
    //
    //     return {...state, allSchools: tempSchools}
    // }
    throw new Error(`No Matching "${action.type}" - action type`)
}

export default filterReducer